import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import Layout from 'components/Layout';
import Meta from 'components/Meta';
import GetStartedCenter from 'components/GetStartedCenter';

// import { Main } from 'styles/features';
import { signUp } from '../utils/contentProvider/function';
import 'styles/features.scss';

const Features = ({ location }) => (
  <Layout location={location}>
    {({ toggleSignup }) => (
      <>
        <Meta
          title="Website Feedback Tool 🌐 | ruttl features"
          description="Leave comments on websites · Edit designs on live website · Inspect CSS · Collaboration for web designers and developers · Invite clients for feedback"
          url="https://ruttl.com/features/"
        />
        <main className="features-styled-main">
          <section className="features-hero">
            <div className="container">
              <h1>
                What can you do
                <br />
                with ruttl?
              </h1>
              <p>
                ruttl solves the time-consuming, clunky design review process
                with a host of features. And the best part? Almost anyone in the
                creative industry can use ruttl!
              </p>
            </div>
          </section>
          <div className="container">
            <div className="feature-main-banner">
              <img
                src="/assets/img/feature-1.png"
                className="feature-1 parallax-it"
                alt="ruttl feature page illustration"
              />
              <img
                src="/assets/img/feature-2.png"
                className="feature-2 parallax-it"
                alt="ruttl feature page illustration"
              />
              <img
                src="/assets/img/feature-3.png"
                className="feature-3 parallax-it"
                alt="ruttl feature page illustration"
              />
              <img
                src="/assets/img/feature-4.png"
                className="feature-4 parallax-it"
                alt="ruttl feature page illustration"
              />
              <img
                src="/assets/img/feature-5.png"
                className="feature-5 parallax-it"
                alt="ruttl feature page illustration"
              />
              <img
                src="/assets/img/feature-6.png"
                className="feature-6 parallax-it"
                alt="ruttl feature page illustration"
              />
              <img
                src="/assets/img/feature-7.png"
                className="feature-7 parallax-it"
                alt="ruttl feature page illustration"
              />
            </div>
          </div>
          <div className="section features-main" id="features">
            <div className="container">
              <div className="feature-set-single" id="comment-live-website">
                <div className="flex flex-wrap align-items-center">
                  <div className="feature-img">
                    <img
                      src="/assets/img/leave-comments-website.jpg"
                      alt="illustration of leaving comment on live website"
                    />
                  </div>
                  <div
                    className="feature-txt"
                    style={{ transform: ' translateY(-50px)' }}>
                    <h2 className="feature-head" style={{ maxWidth: '420px' }}>
                      Leave comments on live websites
                    </h2>
                    <p>
                      Give quick feedback to your team through real-time
                      comments. Keep everyone in the content, design and
                      development teams on the same page. Comment on web pages
                      to share detailed inputs. You could also pin comments on
                      live pixels, give specific creative direction and make
                      work easy for your colleagues.
                    </p>
                    <button
                      type="button"
                      onClick={() => signUp(toggleSignup)}
                      className="button">
                      Comment on Live Website
                    </button>
                  </div>
                </div>
              </div>
              <div className="feature-set-single" id="edit-live-website">
                <div className="flex flex-wrap align-items-center row-reverse-mob">
                  <div className="feature-txt">
                    <h2 className="feature-head" style={{ maxWidth: '310px' }}>
                      Make edits on live websites
                    </h2>
                    <p>
                      Edit live website and get all the precise changes done
                      before that deadline. Change even the tiniest details like
                      font, spacing, live alignment for your live project.
                      Suggest accurate values for all the iterations and make
                      communication simpler.
                    </p>
                    <button
                      type="button"
                      onClick={() => signUp(toggleSignup)}
                      className="button">
                      Edit your Live Website
                    </button>
                  </div>
                  <div className="feature-img">
                    <video
                      src="/assets/img/edit-live-website.mp4"
                      autoPlay
                      muted
                      loop
                      playsInline></video>
                  </div>
                </div>
              </div>
              <div className="feature-set-single" id="edit-website-content">
                <div className="flex flex-wrap align-items-center">
                  <div className="feature-img">
                    <video
                      src="/assets/img/edit-text.mp4"
                      autoPlay
                      muted
                      loop
                      playsInline></video>
                  </div>
                  <div className="feature-txt">
                    <h2 className="feature-head">Edit website content</h2>
                    <p>
                      Make seamless content changes on your website content and
                      see all the changes compiled in real time. Save your time
                      on offline drafts to finalize website content.
                    </p>
                    <button
                      type="button"
                      onClick={() => signUp(toggleSignup)}
                      className="button">
                      Edit Website Content
                    </button>
                  </div>
                </div>
              </div>

              <div className="feature-set-single" id="client-commenting">
                <div className="flex flex-wrap align-items-center row-reverse-mob">
                  <div
                    className="feature-txt"
                    style={{ transform: ' translateY(-30px)' }}>
                    <h2 className="feature-head">Client Commenting</h2>
                    <p>
                      Website feedback has got simpler! Your clients can write
                      and even video comment without logging in on ruttl! Simply
                      send the shareability link to them and they just need to
                      type their name after writing the comment. Your clients
                      don’t face the hassles of signing up. They can also tag
                      other project stakeholders to assign any task and get work
                      done.
                    </p>
                    <button
                      type="button"
                      onClick={() => signUp(toggleSignup)}
                      className="button">
                      Check Client Commenting
                    </button>
                  </div>
                  <div className="feature-img">
                    <video
                      src="/assets/img/guest-commenting.mp4"
                      autoPlay
                      muted
                      loop
                      playsInline></video>
                  </div>
                </div>
              </div>
              <div className="feature-set-single" id="static-image-review">
                <div className="flex flex-wrap align-items-center">
                  <div className="feature-img">
                    <video
                      src="/assets/img/review-static-images.mp4"
                      autoPlay
                      muted
                      loop
                      playsInline></video>
                  </div>
                  <div
                    className="feature-txt"
                    style={{ transform: ' translateY(-30px)' }}>
                    <h2 className="feature-head">Static Image Review</h2>
                    <p>
                      Make designing and development seamless - upload multiple
                      versions of your chosen images on ruttl. Rename, delete
                      image versions at your ease. Tag your teammates, share
                      feedback with them through comments on images. Resolve
                      these comments by marking them done once the task is
                      completed. Edit, unresolve comments, send them to Trello
                      as well on the go.
                    </p>
                    <button
                      type="button"
                      onClick={() => signUp(toggleSignup)}
                      className="button">
                      Review a Static Image
                    </button>
                  </div>
                </div>
              </div>

              <div className="feature-set-single" id="replace-images">
                <div className="flex flex-wrap align-items-center row-reverse-mob">
                  <div className="feature-txt">
                    <h2 className="feature-head">
                      Replace images on live website
                    </h2>
                    <p>
                      Quickly replace images on a live website with our fast
                      website annotation tool. Uploading and trying out various
                      images can be a task. With ruttl, you can quickly upload
                      as many images as needed across dimensions and formats
                      within no time - that too on a live website!
                    </p>
                    <button
                      type="button"
                      onClick={() => signUp(toggleSignup)}
                      className="button">
                      Replace Images on your Website
                    </button>
                  </div>
                  <div className="feature-img">
                    <video
                      src="/assets/img/upload-image.mp4"
                      autoPlay
                      muted
                      loop
                      playsInline></video>
                  </div>
                </div>
              </div>
              <div className="feature-set-single" id="inspect-css">
                <div className="flex flex-wrap align-items-center">
                  <div className="feature-img">
                    <img
                      src="/assets/img/show-activities.png"
                      alt="ruttl feature to inspect CSS code"
                    />
                  </div>
                  <div className="feature-txt">
                    <h2 className="feature-head">Inspect CSS</h2>
                    <p>
                      Effortlessly view clean CSS for your website. Avoid
                      wasting your time with any software to edit website
                      content. Instead, go from seeing your live website to
                      editing it in a click with ruttl. Streamline the
                      accessibility issues, redlines and other technical
                      essentials by inspecting CSS with its ease.
                    </p>
                    <button
                      type="button"
                      onClick={() => signUp(toggleSignup)}
                      className="button">
                      Start Inspecting
                    </button>
                  </div>
                </div>
              </div>

              <div className="feature-set-single" id="add-team">
                <div className="flex flex-wrap align-items-center row-reverse-mob">
                  <div
                    className="feature-txt"
                    style={{ transform: ' translateY(-10px)' }}>
                    <h2 className="feature-head">Add your team</h2>
                    <p>
                      Invite your team members to collaborate on live projects
                      and meet deadlines. Easily notify them about all the
                      changes that you are planning to make on a live project -
                      simply tag them using website comments and give them
                      real-time updates.
                    </p>
                    <button
                      type="button"
                      onClick={() => signUp(toggleSignup)}
                      className="button">
                      Add Team Members
                    </button>
                  </div>
                  <div className="feature-img">
                    <img
                      src="/assets/img/add-team.png"
                      alt="illustration of team members in ruttl"
                    />
                  </div>
                </div>
              </div>

              <div className="feature-set-single" id="review-mobile-web">
                <div className="flex flex-wrap align-items-center">
                  <div className="feature-img">
                    <video
                      src="/assets/img/responsive.mp4"
                      autoPlay
                      muted
                      loop
                      playsInline></video>
                  </div>
                  <div className="feature-txt">
                    <h2 className="feature-head">Review mobile web</h2>
                    <p>
                      Check and keep your website updated across devices.
                      Quickly switch between desktop and mobile view to make
                      changes as needed. Never miss any details or macro
                      changes. Make your website mobile and web friendly through
                      quick reviews on ruttl.
                    </p>
                    <button
                      type="button"
                      onClick={() => signUp(toggleSignup)}
                      className="button">
                      Start Reviewing
                    </button>
                  </div>
                </div>
              </div>
              <div className="feature-set-single" id="versioning">
                <div className="flex flex-wrap align-items-center row-reverse-mob">
                  <div
                    className="feature-txt"
                    style={{ transform: ' translateY(-13px)' }}>
                    <h2 className="feature-head" style={{ maxWidth: '360px' }}>
                      Record every version on website
                    </h2>
                    <p style={{ maxWidth: '380px' }}>
                      Revisit all the website versions you were working on by
                      recording them. Explore all the changes you have made
                      through the save option. Never miss any updates that were
                      made by your teams. Navigate all the versions in no time
                      and easily collaborate to optimize your website.
                    </p>
                    <button
                      type="button"
                      onClick={() => signUp(toggleSignup)}
                      className="button">
                      Create Website Versions
                    </button>
                  </div>
                  <div className="feature-img">
                    <img
                      src="/assets/img/record-version.png"
                      alt="versioning feature of ruttl"
                    />
                  </div>
                </div>
              </div>
              <div className="feature-set-single" id="share-project">
                <div className="flex flex-wrap align-items-center">
                  <div className="feature-img">
                    <img
                      src="/assets/img/share-project-with-clients.png"
                      alt="ruttl project sharing feature"
                    />
                  </div>
                  <div className="feature-txt">
                    <h2 className="feature-head" style={{ maxWidth: '310px' }}>
                      Share project with clients
                    </h2>
                    <p>
                      With the shareable link, always keep your clients in loop
                      by sharing a unique project link with them, which does not
                      require logging in to ruttl. This way, your clients could
                      view all the progress that you and the team have made. You
                      end up reducing the turnaround time and the feedback loop.
                    </p>
                    <button
                      type="button"
                      onClick={() => signUp(toggleSignup)}
                      className="button">
                      Collaborate on a Project
                    </button>
                  </div>
                </div>
              </div>
              <div className="feature-set-single" id="show-activity">
                <div className="flex flex-wrap align-items-center row-reverse-mob">
                  <div
                    className="feature-txt"
                    style={{ transform: ' translateY(-30px)' }}>
                    <h2 className="feature-head">Show all activities</h2>
                    <p>
                      From the Activities panel, check all your website edits,
                      content changes and comments on web pages. This way, your
                      team never loses track of ongoing activities. Anyone from
                      your team could check all the latest activities done on a
                      website project. Your development team could easily
                      navigate those suggestions and use them for the final
                      codes to create pixel perfect designs.
                    </p>
                    <button
                      type="button"
                      onClick={() => signUp(toggleSignup)}
                      className="button">
                      Check the Activities Panel
                    </button>
                  </div>
                  <div className="feature-img">
                    <img
                      src="/assets/img/show-activity.png"
                      alt="ruttl activities tab"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="feature-get-started">
            <GetStartedCenter toggleSignup={toggleSignup} />
          </div>
          <section className="usecases-main" id="usecases">
            <div className="container">
              <h2 className="heading" style={{ marginBottom: '25px' }}>
                ruttl solves a lot of usecases
              </h2>
              {/* <p>
            We built ruttl to be the one-stop solution for your web design
            needs. Here’s a look at the use cases ruttl is perfect for:
          </p> */}

              <div className="flex flex-wrap">
                <div className="usecase-block">
                  <div className="usecase-single" id="usecase-designer">
                    <img
                      src="/assets/img/designer.png"
                      alt="ruttl ui ux designer avatar"
                      title="ruttl ui ux designer avatar"
                    />
                    <h3>
                      <span className="blue-text">UI/UX Designer</span>
                      &nbsp;— Get your designs coded pixel perfectly
                    </h3>
                    <p>
                      As a designer, it’s really important that your design and
                      code match perfectly.&nbsp;
                      <Link to="/">ruttl</Link>
                      &nbsp;allows you to edit the staging link so you can share
                      the exact values with the developers to create pixel
                      perfect designs.
                    </p>
                    <button
                      type="button"
                      onClick={() => signUp(toggleSignup)}
                      className="button">
                      Start Free Trial
                    </button>
                  </div>
                </div>

                <div className="usecase-block">
                  <div className="usecase-single" id="usecase-developer">
                    <img
                      src="/assets/img/developer.png"
                      alt="ruttl developer avatar"
                      title="ruttl developer avatar"
                    />
                    <h3>
                      <span className="blue-text">Developers</span>
                      &nbsp;— Reduce coordination time by collecting exact CSS
                      values
                    </h3>
                    <p>
                      As a developer, you get exact values of the changes needed
                      to be done on the webpage. These changes can be found
                      under the Activity Tab. You can just copy and paste the
                      same on your main source
                    </p>
                    <button
                      type="button"
                      onClick={() => signUp(toggleSignup)}
                      className="button">
                      Start Free Trial
                    </button>
                  </div>
                </div>
                <div className="usecase-block">
                  <div className="usecase-single" id="usecase-content-writer">
                    <img
                      src="/assets/img/content-writer.png"
                      alt="ruttl content writer avatar"
                      title="ruttl content writer avatar"
                    />
                    <h3>
                      <span className="blue-text">Content Writers</span>
                      &nbsp; — Visualize how the content looks on the website
                    </h3>
                    <p>
                      As a content writer, you no longer have to use word
                      documents in order to send website copy. Write the content
                      directly on the live website and visualize how it looks
                      with the design. Word limits are a problem no longer!
                    </p>
                    <button
                      type="button"
                      onClick={() => signUp(toggleSignup)}
                      className="button">
                      Start Free Trial
                    </button>
                  </div>
                </div>
                <div className="usecase-block">
                  <div className="usecase-single" id="usecase-agency">
                    <img
                      src="/assets/img/agencies.png"
                      alt="ruttl agencies avatar"
                      title="ruttl agencies avatar"
                    />
                    <h3>
                      <span className="blue-text">Agencies</span>
                      &nbsp;— Work efficiently on multiple project and never
                      miss a deadline
                    </h3>
                    <p>
                      Agencies can work on multiple web projects seamlessly and
                      invite clients to review and provide feedback. This saves
                      a lot of time and money by reducing the coordination hours
                      between developers and designers.
                    </p>
                    <button
                      type="button"
                      onClick={() => signUp(toggleSignup)}
                      className="button">
                      Start Free Trial
                    </button>
                  </div>
                </div>
                <div className="usecase-block">
                  <div className="usecase-single" id="usecase-product-team">
                    <img
                      src="/assets/img/product-team.png"
                      alt="ruttl avatar for product teams"
                    />

                    <h3>
                      <span className="blue-text">Product teams</span>
                      &nbsp; — Boost intra-team and inter-team collaboration to
                      release new products, features, and more
                    </h3>
                    <p>
                      Not just websites, ruttl also allows you to test internal
                      tools, admin panels, and all types of web apps. Developers
                      and designers can collaborate effectively to get to
                      perfect results.
                    </p>
                    <button
                      type="button"
                      onClick={() => signUp(toggleSignup)}
                      className="button">
                      Start Free Trial
                    </button>
                  </div>
                </div>
                <div className="usecase-block">
                  <div className="usecase-single" id="usecase-marketing-team">
                    <img
                      src="/assets/img/marketing-team.png"
                      alt="ruttl avatar for marketing teams"
                    />

                    <h3>
                      <span className="blue-text">Marketing teams</span>
                      &nbsp;— Reduce dependency on designers and developers;
                      edit websites without coding knowledge
                    </h3>
                    <p>
                      Try on different banners on your website or change the
                      content as you need without depending on designers and
                      developers.
                    </p>
                    <button
                      type="button"
                      onClick={() => signUp(toggleSignup)}
                      className="button">
                      Start Free Trial
                    </button>
                  </div>
                </div>
                <div className="usecase-block">
                  <div className="usecase-single" id="usecase-sales-team">
                    <img
                      src="/assets/img/sales-team.png"
                      alt="ruttl avatar for sales teams"
                    />
                    <h3>
                      <span className="blue-text">Sales teams</span>
                      &nbsp;— Create custom sales experiences to increase sales
                      and offer a personal touch to your customers
                    </h3>
                    <p>
                      Quickly change text, images, styling and bring your
                      visions to life. ruttl enables the sales team to customise
                      demos before each meeting, which increases the bottom line
                      conversion rate
                    </p>
                    <button
                      type="button"
                      onClick={() => signUp(toggleSignup)}
                      className="button">
                      Start Free Trial
                    </button>
                  </div>
                </div>
                <div className="usecase-block">
                  <div className="usecase-single" id="usecase-project-manager">
                    <img
                      src="/assets/img/project-manager.png"
                      alt="ruttl project manager avatar"
                      title="ruttl project manager avatar"
                    />
                    <h3>
                      <span className="blue-text">Project managers</span>
                      &nbsp;— Manage multiple web design projects easily with a
                      bird’s eye view of every single activity
                    </h3>
                    <p>
                      With ruttl, project managers can clearly see the progress
                      on a project. All comments and suggestions are in a single
                      spot to guide changes, resolve pain points, and ensure
                      that the project is moving forward at the right pace.
                    </p>
                    <button
                      type="button"
                      onClick={() => signUp(toggleSignup)}
                      className="button">
                      Start Free Trial
                    </button>
                  </div>
                </div>
                {/* <div className="usecase-block">
              <div className="usecase-single">
                <img src="/assets/img/client.png" alt="Clients" />
                <h3>
                  <span className="blue-text">Clients</span>
                  &nbsp;— Give timely feedback, review changes, and see your
                  ideas come to life
                </h3>
                <p>
                  Clients are included in every step of the project with ruttl.
                  Through ruttl’s Preview mode, clients can make sure the
                  designs align with their vision and switch to Comment mode to
                  offer clear &amp; contextual feedback for any changes they’d
                  like.
                </p>
                <a className="button" href="https://web.ruttl.com/signup" >
                  Start Free Trial
                </a>
              </div>
            </div>
            <div className="usecase-block">
              <div className="usecase-single">
                <img src="/assets/img/freelancer.png" alt="Freelancer" />
                <h3>
                  <span className="blue-text">Freelancers</span>
                  &nbsp;— Independent designers, developers, and content
                  creators can use ruttl to perfect their web projects
                </h3>
                <p>
                  ruttl has been designed keeping in mind both teams and
                  individual users. Freelance designers, developers, and writers
                  can use ruttl to review, edit, and build websites, web apps,
                  and more.
                </p>
                <a className="button" href="https://web.ruttl.com/signup" className="button">
                  Start Free Trial
                </a>
              </div>
            </div> */}
              </div>
              <p style={{ fontSize: '18px', fontWeight: '600' }}>
                To know about the competitive advanatges of ruttl, check out our
                <Link to="/alternatives/">&nbsp;alternatives&nbsp;</Link>
                page.
              </p>
            </div>
          </section>
        </main>
      </>
    )}
  </Layout>
);

Features.propTypes = {
  location: PropTypes.object,
};

export default Features;
